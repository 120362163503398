<template>
  <div>

    <!-- #page-header -->
    <div id="page-header" class="cover -small" data-image-src="assets/img/bg/kontakt.jpg">
      <div class="container">

        <div class="-wrapper">
          <div class="_inner">

            <h1 v-translate>Pravidlá a obchodné podmienky</h1>

          </div>
        </div>

      </div>
    </div>
    <!-- /#page-header -->

    <!-- .section -->
    <section class="section">
      <div class="container">

        <div v-translate>terms_all</div>


      </div>
    </section>
    <!-- /.section -->


  </div>
</template>

<script>
export default {
  name: 'terms',
    metaInfo () {
        return {
            title: this.$gettext('terms_title'),
            titleTemplate: '%s | Webpartner',
            htmlAttrs: {
                lang: 'sk-SK',
                amp: undefined
            },
            link: [
                {rel: 'canonical', href: 'https://webpartner.sk/podmienky'}
            ]
        }
    },

}
</script>
